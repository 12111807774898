
import { defineComponent, ref } from 'vue'
import TmModal from '@/components/shared/modal/TmModal.vue'
import TmButton from '@/components/shared/TmButton.vue'
import TmFormLine from '@/components/shared/TmFormLine.vue'
import { ipRegexp } from '@/services/utils'

export default defineComponent({
  components: { TmFormLine, TmButton, TmModal },
  setup() {
    const whitelist = ref([])

    return {
      ipRegexp,
      whitelist,
    }
  },
})
